import React, { useState } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Img from "../../assets/Screenshot (482) 1.png";
import Vid from "../../assets/Screenshot (610) 1.png";
import Pie from "../../assets/Pie Blue.svg";
import Avatar from "../../assets/new-avatar.svg";
// import { MainBtn } from "../../components/button";
import { useEffect } from "react";
import { ContentWriteup } from "./[id]";
import {
	PieChart,
	BarChart as BarMainChart,
	LineChart as LineMainChart,
} from "../../components/charts/area";

export const MainNewCourseDetails = ({ datum }) => {
	const [lists, setLists] = useState([
		{
			type: "text",
			title: "Header 1",
			body: "Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, ",
		},
		{
			type: "list",
			arr: [
				"Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo in.",
				"Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo in.",
				"Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo in.",
			],
		},
		{
			type: "image",
			img: Img,
		},
		{
			type: "video",
			img: Vid,
		},
		{
			type: "chart",
			img: Pie,
		},
		{
			type: "quote",
			avatar: Avatar,
			author: "Jumai Faith",
			text: "Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia v",
		},
		{
			type: "statement",
			text: "Perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, ",
		},
		{
			type: "question",
		},
	]);

	useEffect(() => {
		if (datum) setLists(datum?.moduleSection);
	}, [datum]);

	// console.log({lists, datum});

	return (
		<div>
			<MainContainer>
				{!datum && <MainHeader text={"Course"} />}
				<div
					className={`${datum ? "" : "tw-mt-10"} tw-w-full tw-flex tw-gap-8`}>
					<div
						className={`${
							datum ? "tw-w-full" : "tw-w-[70%]"
						}  tw-bg-white tw-p-8`}>
						<div className="tw-w-full tw-mt-5">
							<h4 className="tw-text-xl tw-font-bold tw-text-secondary">
								Module {datum?.title}
							</h4>
						</div>
						<div className="tw-mt-8 tw-w-full tw-space-y-4">
							{lists?.map((it, index) => (
								<div
									className="tw-relative tw-pb-12" // Add bottom padding to reserve space
									key={index}>
									<div>
										{it?.type === "text" && (
											<div>
												<h4 className="tw-text-base tw-font-medium tw-text-secondary">
													{it?.title}
												</h4>
												{/* <p className="tw-text-base tw-leading-7 tw-mt-3 tw-font-normal tw-text-secondary">
													{it?.body}
												</p> */}
												{ContentWriteup(
													it?.body,
													"tw-text-base tw-leading-7 tw-mt-3 tw-font-normal tw-text-secondary"
												)}
											</div>
										)}
										{it?.type === "list" && (
											<div>
												<ul className="tw-list-decimal tw-list-inside tw-text-base tw-leading-7 tw-font-normal tw-text-secondary tw-space-y-2">
													{it?.arr?.map((item, i) => (
														<li key={i}>
															{ContentWriteup(
																item,
																"tw-text-base tw-leading-7 tw-mt-3 tw-font-normal tw-text-secondary",
																"span"
															)}
														</li>
													))}
												</ul>
											</div>
										)}
										{["pie-chart"].includes(it?.type) && (
											<>
												<PieChart item={it} />
											</>
										)}
										{["bar-chart"].includes(it?.type) && (
											<>
												<BarMainChart item={it} />
											</>
										)}
										{["line-chart"].includes(it?.type) && (
											<>
												<LineMainChart item={it} />
											</>
										)}
										{["image", "video", "chart"].includes(it?.type) &&
											(it?.type === "video" ? (
												<video
													src={it?.img}
													alt=""
													className="tw-w-full tw-h-56"
													muted
													autoPlay
													poster={Vid}
												/>
											) : (
												<img
													src={it?.img}
													alt=""
													className="tw-w-full tw-h-56"
												/>
											))}
										{it?.type === "quote" && (
											<div>
												<img src={it?.avatar} alt="" className="tw-mx-auto" />
												<p className="tw-text-base tw-text-center tw-leading-7 tw-mt-3 tw-font-normal tw-text-secondary">
													<span className="tw-font-bold">Quote:</span>{" "}
													{/* {it?.text} */}
													{ContentWriteup(
														it?.text,
														"tw-font-normal tw-text-secondary tw-text-base tw-text-center tw-leading-7"
													)}
												</p>
												<p className="tw-mt-5 tw-text-center tw-text-base tw-leading-7 tw-font-normal tw-text-secondary">
													{it?.author}
												</p>
											</div>
										)}
										{it?.type === "statement" && (
											<div>
												<p className="tw-text-base tw-leading-7 tw-font-normal tw-text-secondary">
													<span className="tw-font-bold">Statement:</span>{" "}
													{/* {it?.text} */}
													{ContentWriteup(
														it?.text,
														"tw-font-normal tw-text-secondary tw-text-base tw-leading-7",
														"span"
													)}
												</p>
											</div>
										)}
										{it?.type === "statement-left" && (
											<div className="">
												<p className="tw-text-base tw-leading-7 tw-font-normal tw-text-secondary">
													<span className="tw-font-bold">Statement:</span>{" "}
													{ContentWriteup(
														it?.text,
														"tw-font-normal tw-text-secondary tw-text-base tw-leading-7",
														"span"
													)}
												</p>
											</div>
										)}
										{it?.type === "statement-center" && (
											<div>
												<span className="tw-font-bold tw-text-base tw-text-secondary">
													Statement:
												</span>{" "}
												<p className="tw-text-base tw-text-center tw-leading-7 tw-font-normal tw-text-secondary">
													{ContentWriteup(
														it?.text,
														"tw-font-normal tw-text-secondary tw-text-base tw-text-center tw-leading-7"
													)}
												</p>
											</div>
										)}
										{it?.type === "question" && (
											<div>
												<h5 className="tw-border-b-[0.5px] tw-border-[#00000033] tw-pb-5 tw-text-secondary tw-text-lg satoshi tw-font-medium">
													Question
												</h5>
												<div className="tw-mt-3 tw-w-full tw-space-y-4 tw-rounded-xl tw-p-6 tw-border-[0.5px] tw-border-[#00000033]">
													<div className="tw-relative tw-h-10 tw-border-b-[0.5px] tw-border-b-[#00000033] tw-w-full">
														<div className="tw-absolute tw-top-4 tw-left-3 tw-h-4 tw-w-4 tw-border tw-border-[#0269D0] tw-rounded-full"></div>
														<h6 className="tw-text-base tw-w-full tw-pr-6 tw-font-medium tw-text-secondary tw-text-right">
															Option 1
														</h6>
													</div>
													<div className="tw-relative tw-h-10 tw-border-b-[0.5px] tw-border-b-[#00000033] tw-w-full">
														<div className="tw-absolute tw-top-4 tw-left-3 tw-h-4 tw-w-4 tw-border tw-border-[#0269D0] tw-rounded-full"></div>
														<h6 className="tw-text-base tw-w-full tw-pr-6 tw-font-medium tw-text-secondary tw-text-right">
															Option 1
														</h6>
													</div>
													<div className="tw-relative tw-h-10 tw-border-b-[0.5px] tw-border-b-[#00000033] tw-w-full">
														<div className="tw-absolute tw-top-4 tw-left-3 tw-h-4 tw-w-4 tw-border tw-border-[#0269D0] tw-rounded-full"></div>
														<h6 className="tw-text-base tw-w-full tw-pr-6 tw-font-medium tw-text-secondary tw-text-right">
															Option 1
														</h6>
													</div>{" "}
													<div className="tw-relative tw-h-10 tw-border-b-[0.5px] tw-border-b-[#00000033] tw-w-full">
														<div className="tw-absolute tw-top-4 tw-left-3 tw-h-4 tw-w-4 tw-border tw-border-[#0269D0] tw-rounded-full"></div>
														<h6 className="tw-text-base tw-w-full tw-pr-6 tw-font-medium tw-text-secondary tw-text-right">
															Option 1
														</h6>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export const TemplateBox = ({ children }) => {
  return (
    <div className="tw-border-[0.5px] tw-relative tw-border-secondary tw-rounded-lg tw-p-4 tw-w-full">
      {children}
    </div>
  );
};

const NewCourseDetails = ()=> <MainNewCourseDetails />

export default NewCourseDetails;
