import React, { useState, useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import img from "../../assets/details.png";
import Modulestab from "../../components/tabs/modulestab";
import CertificatesTab from "../../components/tabs/certtab";
import ParticipantTab from "../../components/tabs/participantstab";
import ParticipantTab2 from "../../components/tabs2/participantstab";
import ResultTab from "../../components/tabs/resulttab";
import { useLocation, useNavigate } from "react-router-dom";
import useCourseStore from "../../data/stores/coursestore";
import useAuthStore from "../../data/stores/authstore";
import ReactHlsPlayer from "react-hls-player";
import { Btn, MainBtn } from "../../components/button";
import { ContentWriteup, Overview, Reviews } from "../../pages/courses/[id]";
import { ResourcesGeneraltab } from "../../components/tabs2/modulestab";
import { MainNewCourseDetails } from "../../pages/courses/new";

export const MainCourseDetails = () => {
	const [tab, setTab] = useState("modules");
	const [tab2, setTab2] = useState("overview");

	const navigate = useNavigate(),
		{ state } = useLocation(),
		[datum, setDatum] = useState(null),
		{ data } = useCourseStore(),
		[activeSection, setActiveSection] = useState(null),
		[activeModule, setActiveModule] = useState(null),
		{ userPage } = useAuthStore();

	useEffect(() => {
		data?.docs?.map(it => it?._id === state?._id && setDatum(it));
	}, [data, state]);

	const Arr1 = [
			{
				title: "No. of Modules",
				value: datum?.module?.length || "0",
			},
			{
				title: "No. of Attraction",
				value: "0",
			},
			{
				title: "No. of Participant",
				value: datum?.partakers?.length || "0",
			},
			{
				title: "Certificates Earned",
				value: "0",
			},
		],
		tabs = [
			{
				name: "Modules",
				tab: "modules",
			},
			{
				name: "Certificates Earned",
				tab: "certificates",
			},
			{
				name: "Participants",
				tab: "participants",
			},
			userPage === "institution"
				? {
						name: "Instructors",
						tab: "instructors",
				  }
				: null,
			{
				name: "Results",
				tab: "results",
			},
			{
				name: "Resources",
				tab: "resource",
			},
		];

	if (!state) navigate(-1);

	if (!datum) return;

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Course"} small={datum?.title || "Details"} />
				<div className="tw-w-full tw-mt-5 tw-bg-white tw-rounded-3xl tw-p-4">
					<div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
						<div className="lg:tw-w-[60%] lg:tw-flex-none">
							{activeModule &&
							datum?.moduleType === "dynamic-module-content" ? (
								<div className="tw-mt-4">
									<div className="tw-py-3">
										<MainBtn
											onClick={() => setActiveModule(null)}
											text={"Back"}
										/>
									</div>
									<MainNewCourseDetails datum={activeModule} />
								</div>
							) : (
								<>
									{activeSection ? (
										<>
											{activeSection?.videoLink ? (
												<div
													// style={{ height: "30rem" }}
													className="tw-mt-4 tw-w-full tw-rounded-xl">
													<ReactHlsPlayer
														src={
															activeSection?.videoLink?.url ||
															activeSection?.videoLink?.playback_url
														}
														autoPlay={true}
														controls={true}
														width="100%"
														height="100%"
														loop
														hlsConfig={{
															maxLoadingDelay: 4,
															minAutoBitrate: 0,
															lowLatencyMode: true,
														}}
														title={activeSection?.title}
														className="tw-rounded-xl"
													/>
												</div>
											) : (
												<img
													src={
														datum?.image?.url ||
														datum?.module?.[0]?.image?.url ||
														datum?.module?.[0]?.section?.[0]?.image?.url ||
														img
													}
													alt=""
													className="tw-rounded-xl"
												/>
											)}
										</>
									) : (
										<img
											src={
												datum?.image?.url ||
												datum?.module?.[0]?.image?.url ||
												datum?.module?.[0]?.section?.[0]?.image?.url ||
												img
											}
											alt=""
											className="tw-rounded-xl"
										/>
									)}
									<div className="tw-mt-6">
										<h4 className="tw-text-2xl tw-font-bold tw-text-secondary satoshi">
											{datum?.title}{" "}
											{activeSection ? <>| {activeSection?.title}</> : null}
										</h4>
										{activeSection ? (
											<>
												<div className="tw-h-96">
													<div className="tw-h-full tw-overflow-y-scroll noScroll tw-bg-white tw-rounded-xl tw-p-6 tw-mt-8">
														<div className="tw-flex tw-gap-4">
															{tab2 === "overview" ? (
																<MainBtn
																	onClick={() => setTab2("overview")}
																	text={"Overview"}
																/>
															) : (
																<Btn
																	onClick={() => setTab2("overview")}
																	text={"Overview"}
																/>
															)}
															{tab2 === "transcript" ? (
																<MainBtn
																	onClick={() => setTab2("transcript")}
																	text={"Transcript"}
																/>
															) : (
																<Btn
																	onClick={() => setTab2("transcript")}
																	text={"Transcript"}
																/>
															)}
															{tab2 === "review" ? (
																<MainBtn
																	onClick={() => setTab2("review")}
																	text={"Review"}
																/>
															) : (
																<Btn
																	onClick={() => setTab2("review")}
																	text={"Review"}
																/>
															)}
														</div>
														<div className="tw-mt-6 tw-min-h-full">
															{["transcript", "overview"]?.includes(tab2) && (
																<Overview
																	datum={datum}
																	activeSection={activeSection}
																	tab={tab2}
																/>
															)}
															{tab2 === "review" && (
																<Reviews
																	datum={datum}
																	activeSection={activeSection}
																/>
															)}
														</div>
													</div>
												</div>
											</>
										) : (
											<>
												<div
													style={{
														borderRadius: "8px",
														border: "0.5px solid rgba(37, 37, 37, 0.50)",
													}}
													className="tw-mt-4 tw-p-3 tw-w-full tw-grid tw-grid-cols-4 tw-gap-4">
													{Arr1?.map((item, i) => (
														<div key={i} className="">
															<h5 className="tw-text-main tw-text-xs tw-font-normal satoshi tw-text-opacity-70">
																{item?.title}
															</h5>
															<h4 className="tw-text-base tw-font-medium tw-text-main satoshi tw-underline">
																{item?.value}
															</h4>
														</div>
													))}
												</div>
												{/* <p className="tw-text-xs satoshi tw-font-normal tw-pt-5 tw-text-main">
											{datum?.description}
										</p> */}
												{ContentWriteup(
													datum?.description,
													"tw-text-xs satoshi tw-font-normal tw-pt-5 tw-text-main"
												)}
											</>
										)}
									</div>
								</>
							)}
						</div>
						<div
							style={{
								border: "0.5px solid rgba(37, 37, 37, 0.50)",
								borderRadius: "10px",
							}}
							className="lg:tw-w-[40%] tw-min-h-full tw-overflow-y-scroll noScroll">
							<div
								style={{
									borderBottom: "0.5px solid rgba(37, 37, 37, 0.50)",
								}}
								className="tw-p-2 tw-grid tw-grid-cols-3 tw-gap-1">
								{tabs
									?.filter(it => it)
									?.map((item, i) => (
										<div
											key={i}
											onClick={() => setTab(item?.tab)}
											className={`${
												tab === item.tab
													? "tw-bg-[#0269D0] tw-text-white tw-transition-all tw-ease-in-out tw-duration-300"
													: "tw-bg-[#0269D01A] tw-text-[#0269D0]"
											} tw-p-1 tw-rounded-3xl tw-whitespace-nowrap lg:tw-text-xs tw-text-[8px] tw-text-center tw-cursor-pointer satoshi tw-font-medium satoshi`}>
											{item?.name}
										</div>
									))}
							</div>
							<div className="tw-w-full tw-p-6">
								{tab === "modules" && (
									<Modulestab
										datum={datum}
										setActiveSection={setActiveSection}
										setActiveModule={setActiveModule}
									/>
								)}
								{tab === "certificates" && <CertificatesTab datum={datum} />}
								{/* {tab === "attraction" && <AttractionTab />} */}
								{tab === "participants" && <ParticipantTab datum={datum} />}
								{["instructors"]?.includes(tab) && (
									<ParticipantTab2 datum={datum} tab={tab} />
								)}
								{tab === "results" && <ResultTab datum={datum} />}
								{tab === "resource" && <ResourcesGeneraltab datum={datum} />}
							</div>
						</div>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

const CourseDetails = () => <MainCourseDetails />;

export default CourseDetails;
