import React, { useState, useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import Img from "../../assets/overview.png";
import { Btn, MainBtn } from "../../components/button";
import { Rating } from "react-simple-star-rating";
import Avatar from "../../assets/revavatar.svg";
import Trophy from "../../assets/content.svg";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import { LuMonitorPlay } from "react-icons/lu";
import { CiLock } from "react-icons/ci";
import { useNavigate, useLocation } from "react-router";
import useCourseStore from "../../data/stores/coursestore";
import ReactHlsPlayer from "react-hls-player";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import { IoCheckboxOutline, IoCheckbox } from "react-icons/io5";
import DOMPurify from "dompurify";
import { MainNewCourseDetails } from "./new";

export const createMarkup = html => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

const ViewCourse = () => {
	const [tab, setTab] = useState("overview"),
		[sectionList, setSectionList] = useState(null);
	// const Content = [
	// 	{
	// 		title: "Leadership 101",
	// 		duration: "1h 9mins",
	// 		sections: [
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 		],
	// 	},
	// 	{
	// 		title: "Leadership 101",
	// 		duration: "1h 9mins",
	// 		sections: [
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 		],
	// 	},
	// 	{
	// 		title: "Leadership 101",
	// 		duration: "1h 9mins",
	// 		sections: [
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 		],
	// 	},
	// 	{
	// 		title: "Leadership 101",
	// 		duration: "1h 9mins",
	// 		sections: [
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 		],
	// 	},
	// 	{
	// 		title: "Leadership 101",
	// 		duration: "1h 9mins",
	// 		sections: [
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 		],
	// 	},
	// 	{
	// 		title: "Leadership 101",
	// 		duration: "1h 9mins",
	// 		sections: [
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 			{
	// 				topic: "Leadership 101",
	// 				duration: "1h 9mins",
	// 				locked: true,
	// 			},
	// 		],
	// 	},
	// ];

	const navigate = useNavigate(),
		{ state } = useLocation(),
		[datum, setDatum] = useState(null),
		{ data, updateCourse } = useCourseStore(),
		[activeSection, setActiveSection] = useState(null),
		[activeModule, setActiveModule] = useState(null),
		[percent, setPercent] = useState(0),
		{ user } = useAuthStore();

	useEffect(() => {
		data?.docs?.map(it => it?._id === state?._id && setDatum(it));
	}, [data, state]);

	useEffect(() => {
		if (user?.adminStatusText === "disable") navigate(-1);
	}, [user?.adminStatusText, navigate]);

	if (!state) navigate(-1);

	useEffect(() => {
		if (activeSection) {
			let makeProgressUpdate = async () => {
				let { response, errArr, errMsg } = await apiCall(
					"post",
					`/api/v1/course/manage-progress-update`,
					{
						course: datum?._id,
						section: activeSection?._id,
						module: activeSection?.module?._id || activeSection?.module,
					}
				);
				console?.log({ response, errArr, errMsg });

				if (response) {
					let newCourse = datum;
					newCourse.progress = response?.data || response;
					updateCourse(newCourse);
				}
			};
			makeProgressUpdate();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeSection, datum]);

	useEffect(() => {
		if (datum) {
			setPercent(
				((datum?.progress?.section?.length || 0) /
					datum?.module?.reduce?.((ac, i) => (ac += i?.section?.length), 0)) *
					100 || 0
			);
		}
	}, [datum]);

	if (!datum) return;

	const handleSection = i => {
		setSectionList(sectionList === i ? null : i);
	};

	return (
		<div>
			<MainContainer>
				{activeModule && datum?.moduleType === "dynamic-module-content" ? (
					<></>
				) : (
					<MainHeader />
				)}
				<>
					<div
						className={`tw-flex tw-flex-col lg:tw-flex-row tw-w-full ${
							activeModule && datum?.moduleType === "dynamic-module-content"
								? ""
								: "tw-gap-8"
						}`}>
						<div className="lg:tw-w-[60%] tw-flex-none">
							{activeModule &&
							datum?.moduleType === "dynamic-module-content" ? (
								<div className="tw-mt-4">
									<div className="tw-py-3">
										<MainBtn
											onClick={() => setActiveModule(null)}
											text={"Back"}
										/>
									</div>
									<MainNewCourseDetails datum={activeModule} />
								</div>
							) : (
								<>
									{activeSection ? (
										<>
											{activeSection?.videoLink ? (
												<div
													// style={{ height: "30rem" }}
													className="tw-mt-4 tw-w-full tw-rounded-xl">
													<ReactHlsPlayer
														src={
															activeSection?.videoLink?.url ||
															activeSection?.videoLink?.playback_url
														}
														autoPlay={true}
														controls={true}
														width="100%"
														height="100%"
														loop
														hlsConfig={{
															maxLoadingDelay: 4,
															minAutoBitrate: 0,
															lowLatencyMode: true,
														}}
														title={activeSection?.title}
														className="tw-rounded-xl"
													/>
												</div>
											) : (
												<img
													src={
														datum?.image?.url ||
														datum?.module?.[0]?.image?.url ||
														datum?.module?.[0]?.section?.[0]?.image?.url ||
														Img
													}
													alt=""
													className="tw-rounded-xl"
												/>
											)}
											{/* <img
										src={
											activeSection?.videoLink?.type === "video"
												? `${activeSection?.videoLink?.playback_url?.slice(
														0,
														activeSection?.videoLink?.playback_url?.lastIndexOf(
															"."
														)
												  )}.jpg`
												: activeSection?.videoLink?.url || Img
										}
										alt=""
										className="tw-rounded-xl"
									/> */}
										</>
									) : (
										<img
											src={
												datum?.image?.url ||
												datum?.module?.[0]?.image?.url ||
												datum?.module?.[0]?.section?.[0]?.image?.url ||
												Img
											}
											alt=""
											className="tw-rounded-xl"
										/>
									)}
									<div className="tw-h-96">
										<div className="tw-h-full tw-overflow-y-scroll noScroll tw-bg-white tw-rounded-xl tw-p-6 tw-mt-8">
											<div className="tw-flex tw-gap-4">
												{tab === "overview" ? (
													<MainBtn
														onClick={() => setTab("overview")}
														text={"Overview"}
													/>
												) : (
													<Btn
														onClick={() => setTab("overview")}
														text={"Overview"}
													/>
												)}
												{tab === "transcript" ? (
													<MainBtn
														onClick={() => setTab("transcript")}
														text={"Transcript"}
													/>
												) : (
													<Btn
														onClick={() => setTab("transcript")}
														text={"Transcript"}
													/>
												)}
												{tab === "review" ? (
													<MainBtn
														onClick={() => setTab("review")}
														text={"Review"}
													/>
												) : (
													<Btn
														onClick={() => setTab("review")}
														text={"Review"}
													/>
												)}
												{tab === "resource" ? (
													<MainBtn
														onClick={() => setTab("resource")}
														text={"Resources"}
													/>
												) : (
													<Btn
														onClick={() => setTab("resource")}
														text={"Resources"}
													/>
												)}
											</div>
											<div className="tw-mt-6 tw-min-h-full">
												{["transcript", "overview"]?.includes(tab) && (
													<Overview
														datum={datum}
														activeSection={activeSection}
														tab={tab}
													/>
												)}
												{tab === "review" && (
													<Reviews
														datum={datum}
														activeSection={activeSection}
													/>
												)}
												{tab === "resource" && (
													<ResourcesView
														datum={datum}
														activeSection={activeSection}
													/>
												)}
											</div>
										</div>
									</div>
								</>
							)}
						</div>
						<div
							style={{
								border: "rgba(1, 52, 103, 0.50)",
							}}
							className="tw-flex-1 tw-rounded-xl tw-p-5 lg:tw-w-[40%] tw-bg-white">
							<div className="tw-min-h-full tw-overflow-y-scroll noScroll">
								<h3 className="tw-text-base tw-text-secondary tw-font-medium">
									{datum?.title} Course content
								</h3>
								<div className="tw-mt-3 tw-flex tw-justify-between tw-items-center">
									<img src={Trophy} alt="" className="" />
									<div className="tw-w-64 tw-bg-[#F1F8FF] tw-rounded tw-h-1">
										<div
											className={`tw-w-[${percent}%] tw-bg-skyblue tw-rounded tw-h-full`}
											style={{ width: percent ? `${percent}%` : "" }}
										/>
									</div>
									<small className="tw-text-xs tw-font-medium tw-text-skyblue">
										{((datum?.progress?.section?.length || 0) /
											datum?.module?.reduce?.(
												(ac, i) => (ac += i?.section?.length),
												0
											)) *
											100 || 0}
										%
									</small>
								</div>
								<div className="tw-mt-6 tw-space-y-4">
									{datum?.module?.map((item, i) => (
										<div
											key={i}
											className="tw-px-4 tw-py-2 tw-w-full tw-bg-[#F1F8FF] tw-cursor-pointer tw-rounded-lg">
											<div
												onClick={() => handleSection(i)}
												className="tw-h-16 tw-flex tw-justify-between tw-items-center tw-w-full">
												<div
													onClick={() => {
														if (
															datum?.moduleType === "dynamic-module-content"
														) {
															setActiveModule(item);
														}
													}}>
													<h6 className="tw-text-sm tw-text-main tw-font-medium">
														Module {i + 1}: {item?.title}
													</h6>
													{datum?.moduleType === "dynamic-module-content" ? (
														<>{item?.moduleSection?.length}</>
													) : (
														<CheckProgressViewed datum={datum} module={item} />
													)}
												</div>
												{sectionList === i ? (
													<AiOutlineDown size={10} />
												) : (
													<AiOutlineRight size={10} />
												)}
											</div>
											{sectionList === i && (
												<ul className="tw-px-4 tw-transition-all tw-bg-white tw-ease-linear tw-duration-500">
													{item?.section?.map((item, u) => (
														<li
															key={u}
															style={{
																borderBottom:
																	"0.5px solid rgba(37, 37, 37, 0.50)",
															}}
															className="tw-py-2 tw-flex tw-justify-between tw-items-center">
															{" "}
															<div className="tw-flex tw-gap-3">
																<CheckSectionViewed
																	datum={datum}
																	section={item}
																/>
																<div onClick={() => setActiveSection(item)}>
																	<h6 className="tw-text-sm tw-font-normal tw-text-main">
																		Section {u + 1}.{" "}
																		{item?.title || item?.topic}
																	</h6>
																	<div className="tw-flex tw-gap-1 tw-items-center">
																		<LuMonitorPlay size={15} />
																		<small className="tw-text-xs tw-text-main tw-font-light">
																			{item?.duration}
																		</small>
																	</div>
																</div>
															</div>
															<div>{item?.locked ? <CiLock /> : null}</div>
														</li>
													))}
													{item?.quiz && (
														<li
															style={{
																borderBottom:
																	"0.5px solid rgba(37, 37, 37, 0.50)",
															}}
															onClick={() =>
																navigate("/courses/quiz", {
																	state: { ...state, quizType: item?._id },
																})
															}
															className="tw-py-2 tw-flex tw-justify-between tw-items-center">
															{" "}
															<div>
																<h6 className="tw-text-sm tw-text-main tw-font-medium">
																	Quiz
																</h6>
																<small className="tw-text-xs tw-font-light tw-text-main">
																	{item?.result ? (
																		<>
																			{Number(item?.result?.score || 0).toFixed(
																				2
																			)}
																			%
																		</>
																	) : null}
																</small>
															</div>
														</li>
													)}
												</ul>
											)}
										</div>
									))}
									{datum?.quiz && (
										<div
											onClick={() => navigate("/courses/quiz", { state })}
											className="tw-px-4 tw-py-2 tw-w-full tw-bg-[#F1F8FF] tw-cursor-pointer tw-rounded-lg tw-flex tw-justify-between tw-items-center">
											<div>
												<h6 className="tw-text-sm tw-text-main tw-font-medium">
													Quiz
												</h6>
												<small className="tw-text-xs tw-font-light tw-text-main">
													{datum?.result ? (
														<>{Number(datum?.result?.score || 0).toFixed(2)}%</>
													) : null}
												</small>
											</div>
											<AiOutlineRight size={10} />
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</>
			</MainContainer>
		</div>
	);
};

const CheckSectionViewed = ({ datum, section }) => {
	let [checker, setChecker] = useState(null);
	useEffect(() => {
		if (section && datum) {
			let findSection = datum?.progress?.section?.find(
				it => it === section?._id
			);
			if (findSection) setChecker(true);
		}
		return () => setChecker(null);
	}, [datum, section]);

	return (
		<>
			{checker ? (
				<IoCheckbox className="tw-h-4 tw-w-4 tw-text-skyblue" />
			) : (
				<IoCheckboxOutline className="tw-h-4 tw-w-4 tw-border-skyblue" />
			)}
		</>
	);
};

const CheckProgressViewed = ({ datum, module }) => {
	let [checker, setChecker] = useState(0);
	useEffect(() => {
		if (datum) {
			let counter = 0;
			for (let ui = 0; ui < module?.section.length; ui++) {
				const element = module?.section[ui];
				let findSection = datum?.progress?.section?.find(
					it => it === element?._id
				);
				if (findSection) counter += 1;
			}
			setChecker(counter);
		}
		return () => setChecker(null);
	}, [datum, module]);

	return (
		<>
			<small className="tw-text-xs tw-font-light tw-text-main">
				{checker} | {module?.section?.length}
			</small>
		</>
	);
};

export const Overview = ({ datum, activeSection, tab }) => {
	return (
		<>
			<div className="tw-space-y-4">
				{tab === "transcript" ? (
					<>{ContentWriteup(activeSection?.transcript)}</>
				) : (
					ContentWriteup(activeSection?.description || datum?.description)
				)}
			</div>
		</>
	);
};

export const ContentWriteup = (content, cls, span) => {
	let Tag = span || "div";
	return (
		<>
			<Tag
				className={cls || "tw-text-xs tw-text-main satoshi"}
				dangerouslySetInnerHTML={createMarkup(content || "")}
			/>
		</>
	);
};

export const Reviews = () => {
  const ratings = [70, 60, 80, 20, 40],
    starratings = [4, 2, 2.5, 3, 1],
    reviews = [
      {
        name: "Theresa Webb",
        img: Avatar,
        date: "2023-11-24",
        text: "Qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima",
      },
      {
        name: "Theresa Webb",
        img: Avatar,
        date: "2023-11-24",
        text: "Qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima",
      },
      {
        name: "Theresa Webb",
        img: Avatar,
        date: "2023-11-24",
        text: "Qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima",
      },
      {
        name: "Theresa Webb",
        img: Avatar,
        date: "2023-11-24",
        text: "Qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima",
      },
    ];
  return (
    <>
      <div className="">
        <h6 className="tw-text-base tw-font-bold tw-text-main">
          Student feedback
        </h6>
        <div className="tw-py-6 tw-border-b tw-flex tw-items-center tw-gap-6">
          <div className="">
            <h4 className="tw-text-5xl tw-font-bold tw-text-secondary">4.7</h4>
            <Rating
              initialValue={4}
              onClick={() => null}
              fillColor="#fdcb53"
              size={16}
              readonly
            />
          </div>
          <div className="tw-space-y-4 tw-hidden lg:tw-block">
            {ratings?.map((rating) => (
              <div className="tw-w-80 tw-h-2 tw-bg-[#D6E9FC] tw-rounded">
                <div
                  style={{
                    width: `${rating}%`,
                  }}
                  className={`tw-h-full tw-bg-[#013467] tw-rounded`}
                ></div>
              </div>
            ))}
          </div>
          <div className="tw-flex tw-gap-2 tw-items-center">
            <div
              style={{
                display: "block !important",
              }}
              className=" tw-flex tw-flex-col -tw-space-y-0"
            >
              {starratings?.map((rating) => (
                <Rating
                  size={16}
                  initialValue={rating}
                  onClick={() => null}
                  fillColor="#fdcb53"
                  readonly
                />
              ))}
            </div>
            <div className="tw-space-y-3">
              {ratings?.map((item) => (
                <h6 className="tw-text-[10px] tw-font-medium tw-text-secondary">
                  {item}%
                </h6>
              ))}
            </div>
          </div>
        </div>
        <div className="tw-pt-4">
          <h6 className="tw-text-base tw-font-bold tw-text-main">Reviews</h6>
          <div className="tw-mt-5 tw-space-y-4">
            {reviews?.map((item, i) => (
              <div key={i} className="">
                <div className="tw-flex tw-items-center tw-gap-2">
                  <img src={item?.img} alt="" className="" />
                  <div>
                    <h6 className="tw-text-sm tw-text-main tw-font-bold">
                      {item?.name}
                    </h6>
                    <small className="tw-text-sm tw-text-main tw-font-normal">
                      {item?.date}
                    </small>
                  </div>
                </div>
                <div className="tw-p-2 tw-rounded-xl tw-mt-2 tw-bg-[#D6E9FC] tw-bg-opacity-70 tw-w-full ">
                  <p className="tw-text-sm tw-font-normal tw-text-secondary">
                    {item?.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const ResourcesView = ({ datum }) => {
  let [thisData] = useState(datum?.resource);

  if (!datum) return;

  return (
    <>
      <div className="tw-pt-4">
        <h6 className="tw-text-base tw-font-bold tw-text-main">Resources</h6>
        <div className="tw-mt-5 tw-space-y-4">
          {thisData?.map((item, i) => (
            <div
              key={i}
              className="tw-p-2 tw-rounded-xl tw-bg-[#D6E9FC] tw-bg-opacity-70 tw-w-full"
            >
              <div className="tw-flex tw-items-center tw-gap-2">
                <img src={item?.img} alt="" className="" />
                <div>
                  <h6 className="tw-text-sm tw-text-main tw-font-bold">
                    {item?.title}
                  </h6>
                  <small
                    className="tw-text-sm tw-text-main tw-font-normal tw-cursor-pointer"
                    onClick={() => {
                      let link = "";
                      if (item?.type === "link")
                        if (item?.link) link = item?.link;
                      if (item?.type === "file")
                        if (item?.file) link = item?.file?.url;
                      if (link) window.open(link, "_blank");
                    }}
                  >
                    View
                  </small>
                </div>
              </div>
              {item?.description && (
                <div className="tw-p-2 tw-mt-2">
                  {/* <p className="tw-text-sm tw-font-normal tw-text-secondary">
										{item?.description}
									</p> */}
                  {ContentWriteup(
                    item?.description,
                    "tw-text-sm tw-font-normal tw-text-secondary"
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ViewCourse;
