import React, { useState, useEffect, useContext } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
// import { MyTextInput } from "../../components/inputs";
import Img from "../../assets/chechout.svg";
import Duration from "../../assets/duration.svg";
import Videos from "../../assets/videos.svg";
import { useNavigate, useLocation } from "react-router-dom";
import useCourseStore from "../../data/stores/coursestore";
import useAuthStore, { apiCall } from "../../data/stores/authstore";
import { GlobalState } from "../../data/Context";
import axios from "axios";
import { toast } from "react-toastify";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { usePaystackPayment } from "react-paystack";
import { Button } from "../../components/button";
import { ContentWriteup } from "../../pages/courses/[id]";

const Checkout = () => <CheckoutMain />;

export const CheckoutMain = () => {
	const [card, setCard] = useState(false);
	const navigate = useNavigate(),
		{ state } = useLocation();

	useEffect(() => {
		if (!state) navigate(-1);
	}, [state, navigate]);

	let [provider, setProvider] = useState(""),
		[loading, setLoading] = useState(false),
		[proceed, setProceed] = useState(false),
		{ enroll, data, getCourseToEnroll, getCourse } = useCourseStore(),
		{ user, userPage } = useAuthStore(),
		[paymentData, setPaymentData] = useState(false),
		{ numberWithCommas } = useContext(GlobalState);

	let handleConfirmation = async e => {
		e?.preventDefault();
		let datum = {
			course: state?._id,
			provider,
			email: user?.email,
			name: `${user?.firstName || ""} ${user?.lastName || ""}`,
			telephone: user?.telephone,
		};
		console.log({ state });
		if (!datum?.email || !datum?.course) return;

		if (["company", "institution"]?.includes(userPage)) {
			datum.plan = state?.plan?._id;
			datum.partakers = state?.partakers;
			if (!datum?.plan) return toast.info("Company Course plan is required");
		}

		if (!datum?.provider) return toast.info("Please select a payment method");
		setLoading(true);
		try {
			var res = await axios.post(
				"/api/v1/transactions/generate-transaction-reference",
				{ ...datum }
			);

			toast.success(res.data?.message);
			setProceed(res?.data?.data);
			setLoading(false);
			console.log({ data, res: res?.data });
		} catch (err) {
			console.log({ err });
			let error = err.response?.data?.error;
			if (error) {
				error.forEach(error => toast.error(error?.message));
			}

			if (err?.response?.status === 429) toast.error(err?.response?.data);
			setLoading(false);
			setProceed(false);
		}
		setLoading(false);
	};

	useEffect(() => {}, [paymentData]);

	let configFlutter = {
			public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
			tx_ref: proceed?.reference,
			amount: proceed?.total,
			currency: "NGN",
			payment_options: "card",
			customer: {
				email: user?.email,
				phone_number: user?.telephone,
				name: `${user?.firstName || ""} ${user?.lastName || ""}`,
			},
			customizations: {
				title: `L&D ${state?.title || ""}`,
				description: "Course Payment",
				logo: process.env.REACT_APP_IMAGE_URL,
			},
		},
		handleFlutterPayment = useFlutterwave(configFlutter);

	let configPaystack = {
			email: user?.email,
			amount: Number(proceed?.total * 100),
			publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
			metadata: {
				phone: user?.telephone,
				name: `${user?.firstName || ""} ${user?.lastName || ""}`,
			},
			reference: proceed?.reference
				? proceed?.reference
						?.toString()
						?.split("|")
						?.join("")
						?.split(" ")
						?.join("")
				: "",
		},
		initializePayment = usePaystackPayment(configPaystack);

	let handleSuccess = async ref => {
		setLoading(true);
		// await manageFundWalletFlutterwave(ref, "paystack");
		try {
			let datum = {
				course: state?._id,
				provider,
				email: user?.email,
				name: `${user?.firstName || ""} ${user?.lastName || ""}`,
				telephone: user?.telephone,
				payment_data: ref,
			};

			if (!datum?.email || !datum?.course) return;

			if (["company", "institution"]?.includes(userPage)) {
				datum.plan = state?.plan?._id;
				datum.partakers = state?.partakers;
				if (!datum?.plan) return toast.info("Company Course plan is required");
			}

			if (!datum?.provider) return toast.info("Please select a payment method");
			var res = await axios.post(
				`/api/v1/transactions/manage-${
					!proceed?.toPay ? "learn-now-pay-later" : provider
				}`,
				{
					...datum,
				}
			);

			toast.success(res.data?.message);
			setLoading(false);
			setPaymentData(null);
			setProceed(null);
			navigate("/courses");
			apiCall("get", "/api/v1/course", null, getCourse);
			apiCall("get", "/api/v1/course?enroll=enroll", null, getCourseToEnroll);
		} catch (err) {
			console.log({ err });
			let error = err.response?.data?.error;
			if (error) {
				error.forEach(error => toast.error(error?.message));
			}

			if (err?.response?.status === 429) toast.error(err?.response?.data);
			setLoading(false);
			setProceed(false);
		}
		setLoading(false);
		// setSubmit(true);
	};

	let amountCalc = (item, l = 1) => {
		let discounter = (100 - item?.discount) / 100;

		return state?.amount * discounter * l;
	};

	useEffect(() => {
		if (paymentData) handleSuccess(paymentData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentData]);

	// you can call this function anything
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log("closed");
	};

	const onSuccess = ref => {
		// console.log({ ref });
		setPaymentData(ref);
	};

	if (!data || !enroll) return;

	return (
		<div>
			<MainContainer>
				<MainHeader text={"Checkout"} />
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-8 tw-grid lg:tw-grid-cols-2 tw-gap-16">
					{card ? (
						<div className="tw-h-[447px]">
							<div className="tw-flex tw-gap-6">
								<img
									src={
										state?.image?.url ||
										state?.module?.[0]?.image?.url ||
										state?.module?.[0]?.section?.[0]?.image?.url ||
										Img
									}
									alt=""
									className="tw-w-40"
								/>
								<div>
									<h4 className="tw-text-xl tw-font-bold tw-text-main satoshi">
										{state?.title}
									</h4>
									{/* <p className="tw-text-xs tw-font-normal satoshi tw-text-main">
										{state?.description}
									</p> */}
									{ContentWriteup(
										state?.description,
										"tw-text-xs tw-font-normal satoshi tw-text-main"
									)}
									<div className="tw-mt-1 tw-flex tw-gap-6 tw-items-center">
										<div className="tw-flex tw-items-center tw-gap-2">
											<img src={Videos} alt="" className="" />
											<small className="tw-text-sm tw-font-light satoshi tw-text-main">
												{state?.module?.reduce?.(
													(ac, i) => (ac += i?.section?.length),
													0
												)}{" "}
												Video(s)
											</small>
										</div>
										<div className="tw-flex tw-items-center tw-gap-2">
											<img src={Duration} alt="" className="" />
											<small className="tw-text-sm tw-font-light satoshi tw-text-main">
												{state?.duration ? state?.duration % 60 : 0} Hour(s)
											</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div>
							<h3 className="tw-text-xl tw-font-bold tw-text-secondary satoshi">
								Checkout
							</h3>
							{/* <form action="" className="tw-mt-10">
								<div className="tw-space-y-3">
									<MyTextInput
										label={"Name on card"}
										placeholder={"John Doe"}
									/>
									<MyTextInput
										label={"Card number"}
										placeholder={"1234556y7"}
									/>
								</div>
								<div className="tw-grid tw-grid-cols-2 tw-gap-5">
									<MyTextInput label={"Expiry date"} placeholder={"MM/YY"} />
									<MyTextInput label={"CVV"} placeholder={"CVV"} />
								</div>
								<div className="tw-mt-6 tw-flex tw-items-center tw-gap-4">
									<input
										type="checkbox"
										name=""
										className="tw-h-4 tw-w-4 tw-border"
										id=""
									/>
									<small className="tw-text-xs tw-text-main tw-font-medium">
										Securely save this for my later purchase
									</small>
								</div>
							</form> */}
							<>
								<h3 className="tw-text-xl tw-font-bold tw-text-secondary satoshi py-4">
									Select Payment Method
								</h3>
								<div className="gap-3">
									{process.env.REACT_APP_PAYSTACK_PUBLIC_KEY && (
										<div className="py-3">
											<input
												type="radio"
												name="provider"
												id="paystack"
												className="form-control form-check-inline form-check-input form-check"
												value={provider}
												onChange={e => setProvider(e.target.title)}
												checked={provider === "paystack"}
												title="paystack"
											/>
											<label htmlFor="paystack">
												<img
													src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Paystack_Logo.png/1200px-Paystack_Logo.png?20200430170057"
													alt="Paystack"
													className="img-fluid h-auto w-50"
												/>
											</label>
										</div>
									)}
									{process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY && (
										<div className="py-3">
											<input
												type="radio"
												name="provider"
												id="flutterwave"
												className="form-control form-check-inline form-check-input form-check"
												value={provider}
												onChange={e => setProvider(e.target.title)}
												checked={provider === "flutterwave"}
												title="flutterwave"
											/>
											<label htmlFor="flutterwave">
												<img
													src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Flutterwave_Logo.png/1200px-Flutterwave_Logo.png?20220812092224"
													alt="Flutterwave"
													className="img-fluid h-auto w-50"
												/>
											</label>
										</div>
									)}
								</div>
							</>
						</div>
					)}
					<div className="tw-bg-[#F2F8FF] tw-relative tw-rounded-xl tw-p-6">
						<h3 className="tw-text-xl tw-font-bold tw-text-secondary satoshi">
							Checkout
						</h3>

						{proceed && proceed?.toPay ? (
							<>
								<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
									<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
										Original Price:
									</h5>
									<div>
										<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
											NGN {numberWithCommas(Number(proceed?.amount).toFixed(2))}
										</strong>
									</div>
								</div>
								{["company", "institution"]?.includes(userPage) && (
									<>
										<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
											<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
												No of {userPage === "institution" ? "Student" : "Staff"}
												(s) added
											</h5>
											<div>
												<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
													{numberWithCommas(state?.partakers?.length)}
												</strong>
											</div>
										</div>
										<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
											<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
												Total No of{" "}
												{userPage === "institution" ? "Student" : "Staff"}(s)
											</h5>
											<div>
												<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
													{numberWithCommas(state?.plan?.noOfPartakers)}
												</strong>
											</div>
										</div>
									</>
								)}
								<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
									<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
										Charges
									</h5>
									<div>
										<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
											NGN{" "}
											{numberWithCommas(Number(proceed?.charges).toFixed(2))}
										</strong>
									</div>
								</div>
								<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center tw-border-t tw-border-secondary">
									<h5 className="tw-text-main tw-font-bold tw-text-2xl satoshi">
										Total
									</h5>
									<div>
										<strong className="tw-text-main tw-font-bold tw-text-2xl satoshi">
											NGN {numberWithCommas(Number(proceed?.total).toFixed(2))}
										</strong>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
									<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
										Original Price:
									</h5>
									<div>
										<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
											NGN{" "}
											{numberWithCommas(
												Number(
													["company", "institution"]?.includes(userPage)
														? amountCalc(state?.plan)
														: state?.amount
												).toFixed(2)
											)}
										</strong>
									</div>
								</div>
								{["company", "institution"]?.includes(userPage) && (
									<>
										<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
											<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
												No of {userPage === "institution" ? "Student" : "Staff"}
												(s) added
											</h5>
											<div>
												<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
													{numberWithCommas(state?.partakers?.length)}
												</strong>
											</div>
										</div>
										<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
											<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
												Total No of{" "}
												{userPage === "institution" ? "Student" : "Staff"}(s)
											</h5>
											<div>
												<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
													{numberWithCommas(state?.plan?.noOfPartakers)}
												</strong>
											</div>
										</div>
									</>
								)}
								<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center tw-border-t tw-border-secondary">
									<h5 className="tw-text-main tw-font-bold tw-text-2xl satoshi">
										Total
									</h5>
									<div>
										<strong className="tw-text-main tw-font-bold tw-text-2xl satoshi">
											NGN{" "}
											{numberWithCommas(
												Number(
													["company", "institution"]?.includes(userPage)
														? amountCalc(
																state?.plan,
																state?.plan?.noOfPartakers
														  )
														: state?.amount
												).toFixed(2)
											)}
										</strong>
									</div>
								</div>
							</>
						)}
						<div className="tw-flex tw-justify-center">
							<div className="">
								<div className="tw-flex tw-justify-center">
									{/* <button
										onClick={card ? () => navigate("/") : () => setCard(true)}
										className="tw-h-10 tw-w-60 tw-bg-skyblue tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-white">
										Continue
									</button> */}
									<Button
										onClick={
											!card
												? () => {
														if (!provider)
															return toast.info(
																"Please select a provider to continue"
															);
														setCard(true);
												  }
												: !proceed
												? handleConfirmation
												: !proceed?.toPay
												? () => handleSuccess()
												: () => {
														if (provider === "flutterwave") {
															handleFlutterPayment({
																callback: response => {
																	console.log(response);
																	setPaymentData(response);
																	closePaymentModal();
																},
																onClose: () => {},
															});
														} else {
															initializePayment(onSuccess, onClose);
														}
												  }
										}
										loading={loading}
										children={
											!card
												? "Continue"
												: proceed
												? !proceed?.toPay
													? "enroll now"
													: "make payment"
												: "confirm status"
										}
										css="tw-h-10 tw-w-60 tw-bg-skyblue tw-rounded-3xl tw-text-base satoshi tw-font-medium tw-text-white tw-flex tw-justify-center tw-capitalize tw-mb-3"
									/>
								</div>
								<p className="tw-mt- tw-text-xs tw-text-main satoshi tw-font-medium tw-text-center">
									By completting your purchase you agree to these{" "}
								</p>
								<h6 className="tw-font-bold tw-mt-1 tw-text-xs tw-text-main satoshi tw-text-center">
									TERMS OF SERVICE
								</h6>
							</div>
						</div>
					</div>
				</div>
			</MainContainer>
		</div>
	);
};

export default Checkout;
